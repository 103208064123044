<template>
  <validation-observer #default="{handleSubmit}">
    <b-card header="Add Member">
      <b-form @submit.prevent>

        <b-form-row>
          <b-col md="6">
            <text-input
              id="first-name"
              name="First Name"
              rules="required|max:50|alpha_num"
              :text.sync="user.first_name"
            />
          </b-col>

          <b-col md="6">
            <text-input
              id="last-name"
              name="Last Name"
              rules="required|max:50|alpha_num"
              :text.sync="user.last_name"
            />
          </b-col>

          <b-col md="6">
            <text-input
              id="job-title"
              name="Job Title"
              rules="required"
              :text.sync="user.jobTitle"
            />
          </b-col>

          <b-col md="6">
            <text-input
              id="username"
              name="Username"
              rules="required|max:150"
              :text.sync="user.username"
            />
          </b-col>

          <b-col md="6">
            <text-input
              id="email"
              name="Email"
              rules="required|email|max:150"
              :text.sync="user.email"
            />
          </b-col>

          <b-col md="6">
            <text-input
              id="phone"
              name="Phone"
              rules="required|max:20|phone"
              :text.sync="user.phone"
            />
          </b-col>
        </b-form-row>

        <b-form-row>
          <back />
          <submit-button
            class="ml-auto"
            :handle-submit="handleSubmit"
            :submit="addMember"
          />
        </b-form-row>
      </b-form>
    </b-card>
  </validation-observer>
</template>
<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'AddEntityMember',
  components: { SubmitButton, Back, TextInput },
  data() {
    return {
      user: {
        entityId: this.$store.getters['mainEntity/getEntityId'],
      },
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    addMember() {
      return this.$portalUsers.post('v1/entity/member/create', this.user).then(() => {
        this.successfulOperationAlert('Member is added successfully')
        this.$router.push({ name: 'users-list' })
      })
    },
  },
}
</script>
<style lang="scss">

</style>
